<template>
  <div class="footer">
    <div class="foot-inner">
      <div class="inner-top">
        <div class="foot-left">
          <div class="foot-logo">
            <img class="logo-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/home/logo_3_kuaiqiwu.png" alt="">
          </div>
          <p class="all-line">全国销售热线</p>
          <p class="phone-number">400 860 5600</p>
          <p class="all-line">投诉电话：</p>
          <p class="phone-number">18033436502</p>
          <p class="all-line">商务合作邮箱：</p>
          <p class="phone-number">info@kuaiqiwu.com</p>
        </div>
        <div class="foot-right">
          <div class="right-inner">
            <p class="right-title">全球公司注册</p>
            <p class="right-text" @click="toUrl('HongKong')">中国香港公司注册</p>
            <p class="right-text" @click="toUrl('America')">美国公司注册</p>
            <p class="right-text" @click="toUrl('singapore')">新加坡公司注册</p>
            <p class="right-text" @click="toUrl('kaiman')">开曼公司注册</p>
            <p class="right-text" @click="toUrl('bvi')">BVI公司注册</p>
            <p class="right-text" @click="toUrl('Taiwan')">中国台湾公司注册</p>
            <p class="right-text" @click="toUrl('Britain')">英国公司注册</p>
            <p class="right-text" @click="toUrl('global')">更多公司注册</p>
          </div>
          <div class="right-inner">
            <p class="right-title">秘书服务</p>
            <p class="right-text" @click="toUrl('secretaryHongKong')">中国香港公司年审</p>
            <p class="right-text" @click="toUrl('examinationAmerica')">美国公司年审</p>
            <p class="right-text" @click="toUrl('examinationSingapore')">新加坡公司年审</p>
            <p class="right-text" @click="toUrl('examinationBvi')">BVI公司年审</p>
            <p class="right-text" @click="toUrl('examinationKaiman')">开曼公司年审</p>
          </div>
          <div class="right-inner">
            <p class="right-title">国际公证认证</p>
            <p class="right-text" @click="toUrl('notarizationHague')">海牙认证</p>
            <p class="right-text" @click="toUrl('notarizationEmbassy')">使馆认证</p>
            <p class="right-text" @click="toUrl('notarizationChina')">中国委托人公证认证</p>
            <p class="right-text" @click="toUrl('notarizationTrade')">贸促会认证</p>
          </div>
          <div class="right-inner">
            <p class="right-title">财税筹划</p>
            <p class="right-text" @click="toUrl('fiscalTax')">中国香港公司财税筹划</p>
            <p class="right-text" @click="toUrl('fiscalTaxAmerica')">美国公司财税筹划</p>
          </div>
          <div class="right-inner">
            <p class="right-title">关于</p>
            <p class="right-text" @click="toUrl('about')">关于快企务</p>
            <p class="right-text" @click="toUrl('join')">加入我们</p>
            <p class="right-text">新闻中心</p>
          </div>
        </div>
        <div class="footer-ewm">
          <img class="ewm-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/home/pic_QR_code.png" alt="">
          <p class="small-tips"><span class="margin-1">扫描并关注微信公众号</span></p>
        </div>
      </div>
      <div class="inner-bottom">
        <div class="bottom-box">
          <p class="bottom-text">深圳市福田区福中三路1006号诺德中心12A</p>
          <p class="bottom-text">0755-22655612（深圳总部）</p>
          <p class="bottom-text1">广州市天河区花城大道85号高德置地广场·春2406B</p>
          <p class="bottom-text">020-38819701 （广州分公司）</p>
        </div>
        <div class="bottom-box">
          <p class="bottom-text">上海市静安区铜仁路299号soho东海广场27楼2702B室</p>
          <p class="bottom-text">021-62290510 （上海分公司）</p>
          <p class="bottom-text1">中国香港湾仔卢押道18号海德中心16楼D室</p>
          <p class="bottom-text">400 860 5600 （中国香港分公司）</p>
        </div> 
        <div class="bottom-box">
          <p class="bottom-text">北京建国门外大街19号中信国际大厦22楼01A</p>
          <p class="bottom-text">010-85619385 （北京分公司）</p>
          <p class="bottom-text1">英国伦敦波普勒市场广场22号一层25号 邮编E14 6BU</p>
          <p class="bottom-text">0044+（0）7889215850 （伦敦分公司）</p>
        </div> 
      </div>
    </div>
    <div class="foot-bottom">
      <p class="copyright">Copyright © 2019 深圳金百利国际商务顾问有限公司 版权所有 All Rights Reserved.</p>
      <a class="copyright" href="https://beian.miit.gov.cn/" target="_blank" style="cursor: pointer;display: block;">粤ICP备12035854号-7</a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Footer-page',
  props: {
    msg: String
  },
  data(){
    return{
      
    }
  },
  methods: {
    toUrl(url){
      this.$router.push({name: url})
    },
    drumpTo(){
      location.href = 'https://beian.miit.gov.cn/'
    }

  }
}
</script>
